.shop_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 29px;
  height: 80px;
  background-color: #fff;
  box-shadow: 0px 2px 12px 0px rgba(50, 57, 83, 0.02);
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
}
.shop_header .left,.shop_header .right {
  display: flex;
  align-items: center;
}
.shop_header .shop_name {
  font-size: 32px;
  color: #333;
  margin-right: 55px;
  font-weight: 600;
}
.shop_header .change_shop .ant-select-selector {
  height: 100% !important;
}
.shop_header .change_shop {
  width: 257px;
  height: 38px;
}
.shop_header .change_user {
  margin-right: 53px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
}
.shop_header .change_user .current_phone {
  margin-right: 14px;
}
.shop_header .add_user {
  color: #2F96FF;
}