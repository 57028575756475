.good_detail .detail_title{
  font-size: 20px;
  color: #333;
  font-family: '苹方-简 中黑体';
  font-weight: 500;
  padding-left: 23px;
}
.good_detail .detail_info_block {
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  margin-top: 30px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.02);
}
.good_detail .detail_info_block .info_block_title {
  margin-right: 11px;
  border-bottom: 1px solid #F9F9F9;
  padding: 30px 24px;
  font-size: 20px;
  color:#333;
  font-family: '苹方-简 常规体';
  font-weight: 400;
}
.good_detail .detail_info_block .input_list {
  padding-top: 18px;
  padding-bottom: 16px;
}
.good_detail .detail_info_block .input_item {
  display: flex;
  color: #101010;
  font-size: 16px;
  margin-bottom: 18px;
}
.good_detail .detail_info_block .input_item.center {
  align-items: center;

}
.good_detail .detail_info_block .input_item .input_item_title {
  margin-right: 28px;
  white-space: nowrap;
  width: 126px;
  text-align: right;
}
.good_detail .detail_info_block .must_item::before {
  content:'*';
  color: #FF0054;
  margin-right: 6px;
}
.good_detail .detail_info_block .input_item .input_item_value {
  width: 420px;
}
.good_detail .input_item_value.row {
  display: flex;
}
.good_detail .input_item_value .radio_row .radio_title {
  font-size: 14px;
  color: #333333;
  margin-right: 20px;
}
.good_detail .input_item_value .radio_row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.good_detail .input_item_value .radio_row .ant-checkbox-wrapper,
.good_detail .input_item_value .radio_row .ant-radio-wrapper{
  width: 80px;
  white-space: nowrap;
}
.good_detail .check_block {
  width: 120px;
  height: 60px;
  border-radius: 4px;
  border:1px solid #BBBBBB;
  font-family: '苹方-简 常规体';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.good_detail .check_block:nth-child(n+2) {
  margin-left: 30px;
}
.good_detail .check_block .check_box_title{
  color: #333333;
  font-size: 14px;
}
.good_detail .check_block .check_box_subtitld{
  color: #999999;
  font-size: 12px;
}
.good_detail .text_info {
  color: #5A5A5A;
  font-size: 14px;
  font-family: '苹方-简 常规体';
  margin-top: 16px;
}
.good_detail .text_info2 {
  color: #959595;
  font-size: 13px;
  font-family: '苹方-简 常规体';
}
.good_detail .pay_limit {
  margin-top: 24px;
  color:#333;
  font-size: 14px;
  display: flex;
  white-space: nowrap;
  align-items: center;
}
.good_detail .upload_value {
  padding-top: 2px;
}
.good_detail .upload_info_text {
  font-size: 14px;
  color: #ADADAD;
  font-family: '苹方-简 常规体';
  margin-bottom: 22px;
}
.good_detail .upload_info_text .form_blue_color {
  color: #2B9DFF;
}
.good_detail .submit {
  margin-top: 59px;
  height: 100px;
  position: relative;
}
.good_detail .submit .btn_fixed{
  background-color: #fff;
  height: 100px;
  /* width: 100%; */
  /* position: fixed; */
  /* bottom: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -8px 10px 0px rgba(255, 0, 70, 0.02);
}
.good_detail .submit .submit_btn_item {
  width:320px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 18px;
  font-family: '苹方-简 常规体';
  font-weight: 400;
}
.good_detail .submit .submit_btn_item.ok {
  background-color: #2B9DFF;
  color: #fff;
}
.good_detail .submit .submit_btn_item.fail {
  border:1px solid #DDDDDD;
  background-color: #F9F9F9;
  color: #717171;
  margin-right: 24px;
}