.box_header {
    padding: 15px 0;
    padding-left: 37px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 50px;
    box-sizing: border-box;
}

.login_main {
    position: relative;
    width: 100%;
    height: calc(100vh - 80px);
    background: url('../../static/imgs/login_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

.login_main .box_main_model {
    float: right;
    margin-top: 185px;
    margin-right: 10%;
    width: 545px;
    height: 576px;
    background: #FFFFFF;
    border-radius: 30px;
}

.login_main .box_main_bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 18px;
    margin: auto;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
}

.login_main .model_title {
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 37px;
    text-align: center;
    margin-top: 43px;
    margin-bottom: 41px;
}

.login_main .model_content {
    position: relative;
    width: 464px;
    height: 77px;
    margin: 0 auto;
}

.login_main .model_content1 {
    margin-top: 16px;
}

.login_main .model_content_inp {
    width: 100%;
    height: 100%;
    border: 0;
    border-bottom: 1px solid #E5E5E5;
    outline: none;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding-left: 44px;
}

.login_main .model_content_inp::placeholder {
    color: #999999;
}

.login_main .model_content_icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 22px;
    height: 22px;
}

.login_main .model_content_icon1 {
    width: 20px;
}

.login_main .model_content_icon2 {
    left: auto;
    right: 7px;
    width: 19px;
    height: 14px;
    cursor: pointer;
}
.login_main .model_content_icon3 {
    height: 13px;
}
.login_main .model_content2 {
    width: 464px;
    height: 25px;
    margin: 0 auto;
    margin-top: 27px;
}

.login_main .auto_login_icon {
    width: 17px;
    height: 17px;
}

.login_main .auto_login_text {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 25px;
    margin-left: 12px;
    vertical-align: middle;
}

.login_main .forgot_password {
    float: right;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FC3455;
    line-height: 25px;
    vertical-align: middle;
}

.login_main .login_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 465px;
    height: 66px;
    border-radius: 10px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    margin: auto;
    margin-top: 30px;
    cursor: pointer;
}
.login_main .login_btn:hover {
    opacity: 0.8;
}
.login_main .register_content {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
    margin-top: 46px;
    text-align: center;
}
.login_main .register_content span {
    color: #FC3455;
    cursor: pointer;
}