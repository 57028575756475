.slideList {
  width: 314px;
  background-color: #fff;
  overflow: hidden;
  margin: 0;
}
.slideList .slide_link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
/* 一级菜单样式 */
.slideList .firstMenu {
  position: relative;
  height: 72px;
  padding-left: 39px;
  color: #666666;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.slideList .firstMenu .slide_icon {
  width: 22px;
  margin-right: 14px;
  box-sizing: border-box;
}
.slideList .firstMenu .open_icon {
  width: 12px;
  position: absolute;
  right: 24px
}
/* 二级菜单样式 */
.slideList .sub_slides {
  font-size: 16px;
  color:#333333;
}
.slideList .sub_slides .slide_link {
  width: 100%;
  box-sizing: border-box;
  padding-left: 75px;
  height: 56px;
}