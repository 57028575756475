.renovation {
  width: 100%;
  display: flex;
  padding-left: 30px;
  padding-top: 30px;
}
.renovation .prev_view {
  width: 382px;
  margin-right: 30px;
  border-radius: 6px;
  background-color: #ECEDEF;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
.renovation .prev_view .prev_bg {
  position: absolute;
  width: 348px;
  top: 14px;
  left: 16px;
}
.renovation .prev_view .prev_bg img {
  width: 100%;
}
.renovation .prev_title {
  color: #333;
  font-size: 24px;
  font-weight: 500;
  justify-content: flex-start;
  margin-top: 22px;
  margin-left: 21px;
  position: relative;
  z-index: 2;
}
.renovation .prev_content {
  width: 310px;
  margin-top: 25px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}