.glo_upload_img {
  width: 120px;
  height: 120px;
  border-radius: 4px;
  border:1px solid #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #666666;
  font-family: '苹方-简 常规体';
  font-size: 14px;
  font-weight: 400;
}
.glo_upload_img span {
  margin-top: 10px;
}