.page_content {
  height: 100%;
}
.page_content .main_page {
  display: flex;
  padding-top: 80px;
  min-height: 100%;
}
.page_content .main_page_content {
  padding: 30px 30px 0;
  flex-grow: 1;
}